import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Media } from '../Styles';
import { Route } from 'react-router-dom';

const MenuLink = styled(NavLink)`
  ${ (props) => {
    if(props.responsive !== 'true') {
      return `
        float: right;
        display: block;
        border-bottom: ${ props.active === 'true' ? '6px solid ' + props.theme.primary : ''};
        padding: ${ props.active === 'true' ? '11px 16px' : '14px 16px'};
        font-size: 17px;
        font-weight: 600;
        font-family: Nunito Sans;
        text-decoration: none;
        text-align: center;
        color: ${ props.active === 'true' ? props.theme.primary : '#000000'};

        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;
        
        &:hover {
          border-bottom: 6px solid ${ props.theme.primary };
          padding: 11px 16px;
          color: ${ props.theme.primary };
        }
      `;
    } else {
      return `
        padding: 14px 16px;
        font-size: 17px;
        font-weight: 900;
        font-family: Nunito Sans;
        text-decoration: none;
        text-align: center;
        color: ${ props.active === 'true' ? props.theme.highlight : '#000000'};
        background-color: ${ props.active === 'true' ? `rgb(0, 174, 255);` : `` };
      `;
    }
  }}
  
  

  ${Media.tablet`
    ${ (props) => {
      if(props.responsive === 'true') {
        return `
          float: none;
          display: block;
          
          &:first-child {
            margin-top: 50px;
          }
        `;
      }
    }};
  `};

  ${Media.tablet`
    ${ (props) => {
      if(props.responsive === 'false') {
        return `display: none;`;
      }
    }}
  `};
`;

const MenuItem = (props) => (
  <Route render={({ history }) => (
    <MenuLink to={ props.to } active={ props.active } responsive={ props.responsive } onClick={ () => {
      if(props.responsive === 'true')
        props.toggle();
    }} >
      { props.text }
    </MenuLink>
  )} />
);

export default MenuItem;