import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Theme } from '../Styles';
import { CommentDots } from 'styled-icons/fa-regular/CommentDots';
import { Keyboard } from 'styled-icons/fa-regular/Keyboard';
import { DeviceMobile } from 'styled-icons/octicons/DeviceMobile';
import { Web } from 'styled-icons/material/Web';
import { Question } from 'styled-icons/fa-solid/Question';

const Wrapper = styled.div`
  display: flex;
  max-width: 400px;
  height: 330px;
  min-width: 250px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  background-color: ${ (props) => props.theme.lightGray };
  border: 1px solid ${ (props) => props.theme.mediumGray };
  border-radius: 8px;
  margin 20px;
  padding-top: 10px;
  box-shadow: inset 1px 1px 3px #999;
`;

const Title = styled.div`
  padding: 10px;
  font-family: Nunito Sans;
  font-weight: 900;
  font-size: 16px;
  color: ${ (props) => props.theme.primaryDark };
`;

const Text = styled.div`
  color: ${ (props) => props.theme.primaryDark };
  font-family: Nunito Sans;
  font-weight: 400;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
`;

const StyledLink = styled.h4`
  color: ${ (props) => props.theme.primary };
  cursor: pointer;
  font-family: Nunito Sans;
  font-weight: 600;
  text-align: center;

  &:hover {
    color: ${ (props) => props.theme.primaryDark };
  }
`;

const IconWrapper = styled.div`
  border-radius: 60px;
  padding: 18px;
  text-align: center;
  display: flex;
  min-height: 30px;
  max-height: 100%;
  width: auto;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  border: 6px solid ${ (props) => props.theme.highlight };
  background-color: ${ (props) => props.theme.highlight };

  ${Wrapper}:hover & {
    border: 6px solid ${ (props) => props.theme.primary };
    background-color: ${ (props) => props.theme.primary };
  }
`;

const StyledIcon = `
  color: ${ Theme.primary };
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;

  ${Wrapper}:hover & {
    color: #FFFFFF;
  }
`;

const StyledCommentDots = styled(CommentDots)`${ StyledIcon }`;
const StyledKeyboard = styled(Keyboard)`${ StyledIcon }`;
const StyledMobile = styled(DeviceMobile)`${ StyledIcon }`;
const StyledWeb = styled(Web)`${ StyledIcon }`;
const StyledQuestion = styled(Question)`${ StyledIcon }`;

const getIcon = (type) => {
  switch(type) {
    case 'consulting':
      return (<StyledCommentDots size='30' />);
    case 'custom':
      return (<StyledKeyboard size='30' />);
    case 'mobile':
      return (<StyledMobile size='30' />);
    case 'web':
      return (<StyledWeb size='30' />);
    default:
      return (<StyledQuestion size='30' />);
  }
};

const ServiceBlock = (props) => (
  <Wrapper>
    <IconWrapper>
      { getIcon(props.type) }
    </IconWrapper>
    <Title>{ props.title }</Title>
    <Text>{ props.text }</Text>
    <NavLink to='/services/'><StyledLink to={ props.to }>More info...</StyledLink></NavLink>
  </Wrapper>
);

export default ServiceBlock;