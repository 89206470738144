import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk';

import navigation from './Navigation/reducer';
import contact from './Contact/reducer';
import bio from './Bio/reducer';

const reducers = combineReducers({
  navigation,
  contact,
  bio,
});

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk)
);

const store = createStore(reducers, enhancer);

export default store;