import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getBios } from '../../state/Bio/action';
import Bio from './Bio';

export class BioList extends Component {
  componentDidMount() {
    if(this.props.bios.length < 1) {
      this.props.getBios();
    }
  }

  render() {
    return (
      <div>
        { this.props.bios && this.props.bios.map(
          (bio) => (
            <Bio key={ bio.name } image={ bio.image } name={ bio.name } title={ bio.title } text={ bio.text } isPerson={ bio.isPerson } />
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bios: state.bio.list,
  }
}

const mapDispatchToProps = dispatch => {
  return ({
    getBios: () => dispatch(getBios()),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(BioList);