import React, { Component } from 'react';
import Dashboard from './components/Dashboard';
import Services from './components/Services';
import Blog from './components/Blog';
import About from './components/About';
import Contact from './components/Contact';
import Navmenu from './components/Navmenu';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import { Theme } from './components/Styles';

// const renderMergedProps = (component, ...rest) => {
//   const finalProps = Object.assign({}, ...rest);
//   return (
//     React.createElement(component, finalProps)
//   );
// }

// const PropsRoute = ({ component, ...rest }) => {
//   return (
//     <Route {...rest} render={routeProps => {
//       return renderMergedProps(component, routeProps, rest);
//     }}/>
//   );
// }

class App extends Component {
  render() {
    return (
      <Router>
        <ThemeProvider theme={ Theme }>
          <div>
            <Navmenu />
            
            <Switch>
              <Route path='/' exact component={ Dashboard } />
              <Route path='/about/' exact component={ About } />
              <Route path='/services/' exact component={ Services } />
              <Route path='/blog/' component={ Blog } />
              <Route path='/contact/' exact component={ Contact } />
              <Route component={ NotFound } />
            </Switch>

            <Footer />
          </div>
        </ThemeProvider>
      </Router>
    );
  }
}

export default App;
