// Navigation Actions
export const TOGGLE_RESPONSIVE = 'TOGGLE_RESPONSIVE';

// Contact Actions
export const SEND_CONTACT_START = 'SEND_CONTACT_START';
export const SEND_CONTACT_END = 'SEND_CONTACT_END';
export const TOGGLE_VISIBILITY = 'TOGGLE_VISIBILITY';
export const CLEAR_STATUS = 'CLEAR_STATUS';

// Bio Actions
export const GET_BIOS_START = 'GET_BIOS_START';
export const GET_BIOS_END = 'GET_BIOS_END';