import { SEND_CONTACT_START, SEND_CONTACT_END, TOGGLE_VISIBILITY, CLEAR_STATUS } from '../action-types';

const initialState = {
  isSending: false,
  sendStatus: '',
  visible: false,
}

export const contact = (state = initialState, action) => {
  switch(action.type) {
    case SEND_CONTACT_START:
      return Object.assign({}, state, {
        isSending: true,
        sendStatus: ''
      });
    case SEND_CONTACT_END:
      return Object.assign({}, state, {
        isSending: false,
        sendStatus: action.status
      });
    case TOGGLE_VISIBILITY:
      return Object.assign({}, state, {
        visible: !state.visible
      });
    case CLEAR_STATUS:
      return Object.assign({}, state, {
        sendStatus: ''
      });
    default:
      return state;
  }
}

export default contact;