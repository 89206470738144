import React from 'react';
import styled from 'styled-components';
import { Wrapper, ImageDivStyles, TitleDiv, TitleTextStyles } from '../Styles';

const ImageDiv = styled.div`${ ImageDivStyles }`;

const TitleText = styled.div`${ TitleTextStyles }`;

const Blog = () => (
  <Wrapper>
    <ImageDiv img='/images/maintenence.jpg'>
      <TitleDiv>
        <TitleText>Section Down For Maintenence</TitleText>
        <TitleText small>please check back soon</TitleText>
      </TitleDiv>
    </ImageDiv>
  </Wrapper>
);

export default Blog;