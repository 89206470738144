import React, { Component } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { connect } from 'react-redux';
import { toggleVisible } from '../../state/Contact/actions';
import Blurb from '../common/Blurb';
import Button from '../common/Button';
import ServiceBlock from './ServiceBlock';
import { Content, Wrapper, Media, Min, NormalText } from '../Styles';

const ImageDiv = styled.div`
  background-image: linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.1)), url('/images/airport.jpg');
  width: 100%;
  object-fit: cover;
  background-size: cover;
  ${Media.tablet`height: 400px;`}
`;

const TitleDiv = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  ${Media.desktop`width: 95%;`}
  height: 600px;
  ${Media.tablet`height: 400px;`}
  text-align: center;
`;

const LargeText = styled.span`
  font-family: Nunito Sans;
  font-weight: 900;
  font-size: ${ (props) => props.small ? '20px' : '48px'};
  ${Media.tablet`font-size: ${ (props) => props.small ? '12px' : '28px'};` }
  ${ (props) => {
    if(props.primary) {
      return css`
        color: rgba(255, 255, 255, 0);
        animation-name: ${fadeIn};
        animation-delay: 1s;
        animation-duration: 1s;
        animation-fill-mode: forwards;
      `
    } else {
      return css`
        color: ${ props.theme.secondaryDark }
        text-shadow: 2px 2px #000000;
      `;
    }
  }};

`;

const ProcessImage = styled.img`
  display: flex;
  width: 1024;
  max-width: 100%;
  height: auto;
  ${Media.portrait`display: none;`};
`

const ProcessMobile = styled.div`
  margin: 10px auto;
  ${Min.portrait`display: none;`};
`;

const StepTitle = styled.div`
  color: ${ (props) => props.theme.secondaryDark };
  font-family: Nunito Sans;
  font-weight: bold;
  font-size: 1.5em;
`;

const fadeIn = keyframes`
  from {
    display: none;
  }

  to {
    color: rgba(255, 255, 255, 1);
    text-shadow: 2px 2px rgba(0, 0, 0, 1);
  }
`;

const TwoByTwo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
`;

export class Dashboard extends Component {

  componentDidMount(props) {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Wrapper>
        <ImageDiv>
          <TitleDiv>
            <LargeText primary>YOUR CUSTOMERS <LargeText>DEMAND</LargeText> TECHNOLOGY</LargeText>
            <LargeText primary small>LEVERAGE EXPERT SOFTWARE SOLUTIONS TO DRIVE <LargeText small>CUSTOMER GROWTH</LargeText> AND RETENTION</LargeText>
          </TitleDiv>
        </ImageDiv>
        <Content>
          <Blurb title='Why Phinite'>
            <NormalText>
              Phinite Software, LLC is a results-driven software solutions provider specializing in enabling businesses to rapidly and cost-effectively
              bring their software-based products and services to market, while ensuring software security, reliability, and state-of-the-art extensibility. 
              Here at Phinite, we are firm believers in applying the single responsibility principle to business. We are at our best when we can focus on 
              what we do best, without having to wear too many hats. Software is what WE do best. Let us help you focus on what you do best, without being 
              bogged down by the complexities of modern software development.
            </NormalText>
            <Button primary text="Let's get started" onClick={ () => this.props.toggleVisible() }/>
          </Blurb>
          <Blurb title='Our Process'>
            <ProcessImage alt='process image' src='/images/process.png' />
            <ProcessMobile>
              <StepTitle>Discover</StepTitle>
              <NormalText>
                We work with you to identify and understand your most profitable business 
                opportunities, and collaborate with your team in order to choose the 
                best software solution for your business.
              </NormalText>
            </ProcessMobile>
            <ProcessMobile>
              <StepTitle>Create</StepTitle>
              <NormalText>
                Our experts define a clear plan to build your solution, then use our agile
                process to build and deliver quality software on time, and on budget.  We 
                strive to consistently deliver a solution that exceeds all expectations.
              </NormalText>
            </ProcessMobile>
            <ProcessMobile>
              <StepTitle>Execute</StepTitle>
              <NormalText>
                We collaborate with you to generate a precise launch plan, and provide
                training for your internal teams to fully understand your products’
                capabilities.  At this time, we will also begin helping you plan for ongoing
                development.
              </NormalText>
            </ProcessMobile>
            <ProcessMobile>
              <StepTitle>Improve</StepTitle>
              <NormalText>
                With your fresh new product in the hands of your customers; this is the time
                to learn from their feedback and plan improvements.  We will assist in
                analysing customer insights, developing a living product roadmap, and
                planning for the ongoing release cycle.
              </NormalText>
            </ProcessMobile>
          </Blurb>
          <Blurb title='Our Services'>
            <TwoByTwo>
            <ServiceBlock 
              type='consulting' 
              title='Software Consulting'
              text='Our experts provide software consulting services such as managing security, testing strategies, concept development, tech stack selection, and more.'
            />
            <ServiceBlock 
              type='custom' 
              title='Custom Software'
              text='Full life-cycle development services for custom software solutions.  Bring us your vision, and we will make it reality.'
            />
            <ServiceBlock 
              type='mobile' 
              title='Mobile Development'
              text='Initial concept or mid project, our experts will help you reach more customers on iOS, Android, and Windows mobile devices.'
            />
            <ServiceBlock 
              type='web' 
              title='Web Applications'
              text='Design, architecture, development, integration, maintenance, and more.  We help you get the most out of your B2C, B2B, and enterprise solutions 
              with secure, scalable, responsive web applications.'
            />
            </TwoByTwo>
          </Blurb>
        </Content>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return ({
    toggleVisible: () => dispatch(toggleVisible())
  });
}

export default connect(null, mapDispatchToProps)(Dashboard);