import { GET_BIOS_START, GET_BIOS_END } from '../action-types';

const initialState = {
  isFetching: false,
  list: [],
}

export const bio = (state = initialState, action) => {
  switch(action.type) {
    case GET_BIOS_START:
      return Object.assign({}, state, {
        isFetching: true
      });
    case GET_BIOS_END:
      return { isFetching: false, list: action.data };
    default:
    return state;
  }
}

export default bio;