import React from 'react';
import styled from 'styled-components';

const StyledLogo = styled.img`
  float: left;
  margin-top: 5px;
  margin-left: 5px;
  cursor: pointer;
  height: 40px;
`;

const Logo = (props) => <StyledLogo alt='Phinite Software' src='/images/logo-dark2.png' onClick={() => props.history.push('/') } />;

export default Logo;