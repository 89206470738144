import axios from 'axios';
import { SEND_CONTACT_START, SEND_CONTACT_END, TOGGLE_VISIBILITY, CLEAR_STATUS } from '../action-types';

export const toggleVisible = () => {
  return {
    type: TOGGLE_VISIBILITY
  }
}

const StartSendContact = () => {
  return {
    type: SEND_CONTACT_START
  }
}

const EndSendContact = (response) => {
  return {
    type: SEND_CONTACT_END,
    status: response
  }
}

const ClearStatus = () => {
  return {
    type: CLEAR_STATUS
  }
}

const SendContact = (data) => {
  return axios.post('https://xy3hxsdo6e.execute-api.us-east-1.amazonaws.com/production/contact', data);
}

const ResetStatus = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, 2000);
  });
}

export const sendContact = (data) => {
  return dispatch => {
    dispatch(StartSendContact());
    return SendContact(data).then( (response) => {
      dispatch(EndSendContact(response.data));
      return ResetStatus().then( () => {
        dispatch(toggleVisible());
        dispatch(ClearStatus());
      });
    });
  }
}