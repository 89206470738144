import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  width: ${ (props) => props.width };
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  font-family: Nunito Sans;
  font-weight: bold;
  background-color: ${ (props) => props.primary ? props.theme.primary : props.theme.secondaryDark };
  color: ${ (props) => props.theme.highlight };
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;

  &:hover {
    background-color: ${ (props) => props.primary ? props.theme.secondaryDark : props.theme.primary };
    border-radius: 6px;
  }
`;

const Button = (props) => <StyledButton primary={props.primary} onClick={props.onClick} width={ props.width }>{props.text}</StyledButton>;

export default Button;