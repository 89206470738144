import React, { Component } from 'react';
import styled from 'styled-components';
import { Menu } from 'styled-icons/material/Menu';
import { Media, Min } from '../Styles';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { toggleResponsive } from '../../state/Navigation/actions';
import { toggleVisible } from '../../state/Contact/actions';
import Logo from './Logo';
import MenuItem from './MenuItem';
import Button from '../common/Button';
import Contact from '../Contact';

const Topnav = styled.div`
  overflow: hidden;
  position: fixed;
  width: 100%;
  background-color: ${ (props) => props.theme.highlight };
  box-shadow: 0 .2em 1em #000000;
  ${Media.tablet`
    ${ (props) => {
        if(props.responsive === 'true') {
          return `
            background-color: rgba(255, 255, 255, 0.8);
          `;
        }
      }
    };
  `};
`;

const Inner = styled.div`
    width: 70%;
    ${Media.desktop`width: 100%`};
    margin: auto;
    overflow: hidden;
`;

const Hamburger = styled(Menu)`
  display: none;
  margin: 10px;
  ${Media.tablet`
    float: right;
    display: block;
    ${ (props) => {
      if(props.responsive === 'true') {
        return `
          color: ${ props.theme.primary };
          position: fixed;
          right: 0;
          top: 0;
        `
      }
    }}
  `}
`;

const ContactButton = styled.div`
  float: right;
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  ${Media.tablet`display: none;`};
`;

const ContactButtonMobile = styled.div`
  float: right;
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  ${ (props) => props.responsive === 'true' ? `display: none;` : `` };
  ${Min.tablet`display: none;`};
`;

export class Navmenu extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.props.toggleResponsive();
  }

  render() {
    return (
      <Route render={({ history }) => (
        <div>
          <Contact visible={ this.props.showContact } toggle={() => this.props.toggleVisible()} />
          <Topnav responsive={ this.props.responsive }>
            <Inner>
              <Logo history={ history } />
              <ContactButton>
                <Button primary={ true } text='Contact Us!' onClick={ () => this.props.toggleVisible() } />
              </ContactButton>
              <div>
                { this.props.menuItems.map(item => (
                  <MenuItem 
                    key={ item.id } 
                    to={ item.link } 
                    text={ item.name } 
                    active={ item.link === this.props.location.pathname ? 'true' : 'false' } 
                    responsive={ this.props.responsive }
                    toggle={ () => this.props.toggleResponsive() }
                  />
                )) }
              </div>
              <Hamburger size='30' title='menu' onClick={ () => this.props.toggleResponsive() } responsive={ this.props.responsive } />
              <ContactButtonMobile responsive={ this.props.responsive }>
                <Button primary={ true } text='Contact Us!' onClick={ () => this.props.toggleVisible() } />
              </ContactButtonMobile>
            </Inner>
          </Topnav>
        </div>
      )} />
    );
  }
}

const mapStateToProps = state => {
  return {
    responsive: state.navigation.responsive,
    menuItems: state.navigation.links,
    showContact: state.contact.visible,
  }
}

const mapDispatchToProps = dispatch => {
  return ({
    toggleResponsive: () => dispatch(toggleResponsive()),
    toggleVisible: () => dispatch(toggleVisible())
  });
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Navmenu);