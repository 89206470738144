import styled, { css } from 'styled-components';

const _sizes = {
  desktop: 1024,
  tablet: 768,
  portrait: 480,
  phone: 320
}

export const Theme = {
  info: '#0066cc',
  success: '#37b400',
  warning: '#ffb400',
  error: '#db4240',
  primary: '#00AEFF',
  primaryDark: '#0F263D',
  secondary: '#FFD700',
  secondaryDark: '#FF9700',
  lightGray: '#E2EDFF',
  mediumGray: '#D5DEED',
  highlight: '#FFFFFF',
  black: '#000000',
}

export const Media = Object.keys(_sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${_sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export const Min = Object.keys(_sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${_sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export const LightTextStyles = css`
  color: ${ Theme.primaryDark };
  text-align: ${ (props) => props.left ? 'left' : 'center' }
  font-size: 16px;
  font-family: Nunito Sans;
  font-weight: 400;
  padding-right: 10px;
`;

export const NormalText = styled.div`
  color: ${ Theme.primaryDark };
  font-size: 1em;
  font-family: Nunito Sans;
  font-weight: 400;
  width: 90%;
  margin: auto;
`;

export const Content = styled.div`
  margin: auto;
  width: 70%;
  ${Media.desktop`width: 95%;`}
  color: ${ Theme.primaryDark };
`;

export const Wrapper = styled.div`
  background-color: ${ Theme.highlight };
  color: ${ Theme.primaryDark };
  font-family: Nunito Sans;
  padding-top: 50px;
`;

export const HeavyText = styled.div`
  display: flex;
  color: ${ Theme.primaryDark };
  font-family: Nunito Sans;
  font-weight: bold;
  font-size: 1.5em;
`;

export const LogoMini = styled.img`
  width: 128px;
  display: block;
  max-width: 100%;
  height: auto;
  padding: 10px;
`;

export const ImageDivStyles = css`
  overflow: hidden;
  background-image: linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.1)), url(' ${ (props) => props.img } ');
  background-position: center;
  width: 100%;
  object-fit: contain;
  background-size: cover;
  ${Media.tablet`height: 400px;`}
`;

export const TitleDiv = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  ${Media.tablet`width: 95%;`}
  height: 600px;
  ${Media.tablet`height: 400px;`}
  text-align: center;
`;

export const TitleTextStyles = css`
  font-family: Nunito Sans;
  font-weight: 600;
  font-size: ${ (props) => props.small ? '24px' : '48px'};
  ${Media.tablet`font-size: ${ (props) => props.small ? '18px' : '38px'};` }
  color: #FFFFFF;
`;

export const Title = styled.div`
  display: flex;
  color: ${ Theme.primaryDark };
  font-family: Cabin;
  font-weight: bold;
  font-size: 1.8em;
  text-align: center;
`;

export const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const BracketsStyles = css`
  display: ${ (props) => props.noTitle ? 'none' : 'flex' };
  color: ${ Theme.primary };
  font-family: Cabin;
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
`;