import { GET_BIOS_START, GET_BIOS_END } from '../action-types';

// TEST DATA
const testData = [
  { 
    name: 'Robert Roemmele', 
    title: 'Chief Executive Officer', 
    image: '/images/headshot.png', 
    isPerson: true,
    text: 'Robert has spent nearly 20 years working in software development.  He brings a diverse background of knowledge and experience in many industries.  Robert has worked with clients ranging from federal (IRS, SSA, DoD), to small (startups, indie studios) and large organizations (Stanley Black & Decker, MedStar Health).  He served honorably in the U.S. Army from 2005 to 2013, supporting signal systems, information systems, and software development.  Robert had a vision to create a company that would provide professional software solutions second to none; One that could take a business problem, no matter how complex, and transform it into a modern, scalable solution.  In 2016, Robert founded Phinite Software, LLC to accomplish that goal.'
  },
  {
    name: 'Cody Brandon',
    title: 'Director, Information Technology',
    image: '/images/headshot-cody.png',
    isPerson: true,
    text: 'Cody has been active in the technology industry for over 12 years and is a walking library of knowledge.  He is an expert in IT infrastructure and architecture.  Cody is also a honorable veteran of the U.S. Army; Where he provided support of mission ready IT infrastructure, networking, and automations.  He has supported and managed countless large-scale projects in both the public and private sectors.  Between his expertise and enormous passion for all things technical, Cody is an integral player in helping drive our mission of quality and efficiency.'
  }
];

const StartGetBios = () => {
  return {
    type: GET_BIOS_START
  }
}

const EndGetBios = (response) => {
  return {
    type: GET_BIOS_END,
    data: response
  }
}

const GetBios = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(testData);
    }, 200);
  });
}

export const getBios = () => {
  return dispatch => {
    dispatch(StartGetBios());
    return GetBios().then( (response) => {
      dispatch(EndGetBios(response));
    });
  }
}