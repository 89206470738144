import React from 'react';
import styled from 'styled-components';
import { Media, NormalText, HeavyText } from '../Styles';

const BioWrapper = styled.div`
  display:flex;
  flex-direction: row;
  ${Media.tablet`flex-direction: column;`}
  max-width: 100%;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const BioImage = styled.img`
  width: ${ (props) => props.large ? '400px' : '200px' };
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
  border-radius: 200px;
  border: ${ (props) => props.noBorder ? `none` : `2px groove ${props.theme.secondaryDark}` };
`;

const BioInner = styled.div`
  display: flex;
  flex-direction: column;
`;

const BioTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  width: 90%;
  margin: auto;
  text-align: center;

  ${Media.tablet`
    flex-direction: column;
    padding: 0px 0px 10px 0px;
  `};
`;

const BioTitleText = styled(HeavyText)`
  align-self: center;
`;

const TextBlock = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;

  ${Media.tablet`
    text-align: center;
  `}
`;

const renderPerson = (props) => {
  if(props.isPerson) {
    return (
      <BioTitle>
        <BioTitleText>{ props.name }</BioTitleText>
        <BioTitleText>{ props.title }</BioTitleText>
      </BioTitle>
    );
  } else {
    return '';
  }
}

const Bio = (props) => (
  <BioWrapper>
    <BioImage alt={ `${ props.name } headshot` } src={ props.image } />
    <BioInner>
      { renderPerson(props) }
      <TextBlock>
        <NormalText>{ props.text }</NormalText>
      </TextBlock>
    </BioInner>
  </BioWrapper>
);

export default Bio;