import React from 'react';
import styled from 'styled-components';
import { Media, Content, Wrapper, NormalText } from '../Styles';

import Blurb from '../common/Blurb';

export const HeavyText = styled.div`
  display: flex;
  color: ${ (props) => props.theme.secondaryDark };
  font-family: Nunito Sans;
  font-weight: bold;
  font-size: 1.5em;
`;

const HeavyTextDark = styled.div`
  color: ${ (props) => props.theme.primaryDark };
  font-size: 1.5em;
  font-family: Nunito Sans;
  font-weight: bold;
  width: 90%;
  margin: auto;
`;

const ImageRowWrapperLeft = styled.div`
  display: flex;
  float: left;
  flex-direction: row;
  max-width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin: auto;
  padding: 10px;
  text-align: left;
  ${Media.portrait`
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `};

`;

const ImageRowWrapperRight = styled.div`
  display: flex;
  float: right;
  flex-direction: row;
  max-width: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  margin: auto;
  padding: 10px;
  text-align: right;
  ${Media.portrait`
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  `};
`;

const ImageRowImage = styled.img`
  display: flex;
  width: ${ (props) => props.large ? '400px' : '200px' };
  max-width: 100%;
  height: auto;
  margin: auto;
  border-radius: 8px;
`;

const TextBlockLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  ${Media.portrait`text-align: center;`};
`;

const TextBlockRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: right;
  ${Media.portrait`text-align: center;`};
`;

const ImageRowWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: 10px auto;
`;

const Services = () => {
  window.scrollTo(0, 0);
  return(
    <Wrapper>
      <Content>
        <Blurb title='Software Consulting'>
          <ImageRowWrapper>
            <ImageRowWrapperLeft>
              <ImageRowImage alt='cloud services' src='/images/cloud.png' />
              <TextBlockLeft>
                <HeavyTextDark>Cloud Integration</HeavyTextDark>
                <NormalText>
                  Our experts can help you plan and execute your applications transition "into the cloud".  We can assist with projects of all shapes and
                  sizes, from serverless web applications to complex enterprise software.  Choosing a cloud services platfrom is hard, and executing a
                  full migration is even harder.  Let us help you complete your transition with ease.
                </NormalText>
              </TextBlockLeft>
            </ImageRowWrapperLeft>
            <ImageRowWrapperRight>
              <TextBlockRight>
              <HeavyTextDark>Security</HeavyTextDark>
                <NormalText>
                  Here at Phinite, we take security and privacy seriously.  The landscape of networked technologies is constantly in flux, and new 
                  vulnerabilities are born every day.  Security risks are damaging, costly, and downright dangerous.  Don't let your business be the next
                  to make the news for the wrong reasons.  Let our experts guide your team to tighter security, and peace of mind.
                </NormalText>
              </TextBlockRight>
              <ImageRowImage alt='security services' src='/images/security.png' />
            </ImageRowWrapperRight>
            <ImageRowWrapperLeft>
              <ImageRowImage alt='testing services' src='/images/testing.png' />
              <TextBlockLeft>
              <HeavyTextDark>Testing Strategy</HeavyTextDark>
                <NormalText>
                  Two words that no development team likes to hear: Technical Debt.  Software development is an extremely complex process involving
                  multiple professionals tackling an ever changing problem.  A lack of proper testing can quicly lead your team's progress to a screeching 
                  halt, and decimate your projects' timelines with the need to catch up on neglected testing.  Don't wait until it becomes a problem,
                  let our team guide you on implementing proper testing strategies now, at any stage of development.
                </NormalText>
              </TextBlockLeft>
            </ImageRowWrapperLeft>
            <ImageRowWrapperRight>
              <TextBlockRight>
              <HeavyTextDark>Tech-stack Selection</HeavyTextDark>
                <NormalText>
                  Should we go serverless?  Is NodeJS faster than Java?  Do we need or want cloud services?  What's the best strategy for our data?
                  What's the best architecture for our MVP?  How do we best integrate with that COTS product? Questions like these come up constantly 
                  when planning any new software project.  We at Phinite have seen it all, and we make it our never ending mission to always stay on
                  the bleeding edge of software and technology.  Let our experts guide you, and rest assured that you will always have the right tools
                  for the job.
                </NormalText>
              </TextBlockRight>
              <ImageRowImage alt='techstack services' src='/images/techstack.png' />
            </ImageRowWrapperRight>
          </ImageRowWrapper>
        </Blurb>
        <Blurb title='Web Applications'>
          <ImageRowWrapper>
            <ImageRowWrapperLeft>
              <ImageRowImage alt='enterprise web services' src='/images/enterprise.png' />
              <TextBlockLeft>
                <HeavyTextDark>Enterprise Software Applications</HeavyTextDark>
                <NormalText>
                  Does technology drive business, or does business drive technology?  Either way, no-one will argue that our organizations are becoming
                  more and more reliant on technology; and the process of planning, developing, maintaining, and upgrading the software behind your entire
                  organization is a daunting task indeed.  Phinite can help!  No matter what the state of your current enterprise applications, we can 
                  help you ensure that the software driving your business runs like the well oiled machine it is intended to be.
                </NormalText>
              </TextBlockLeft>
            </ImageRowWrapperLeft>
            <ImageRowWrapperRight>
              <TextBlockRight>
              <HeavyTextDark>E-commerce</HeavyTextDark>
                <NormalText>
                  In our day and age, you can buy pretty much anything online.  As much as we love the purity and social warmth of brick and mortar, our
                  world is changing... and so are our competitors.  Having a strong, well functioning online presence is guaranteed to drive more customers
                  to spend their hard earned cash with you.  Don't let technology leave your business behind.  We can help you bring your brand to the digital
                  world, and drive massive increases in sales to your tech savvy customers. 
                </NormalText>
              </TextBlockRight>
              <ImageRowImage alt='ecommerce web services' src='/images/ecommerce.png' />
            </ImageRowWrapperRight>
            <ImageRowWrapperLeft>
              <ImageRowImage alt='intranet web services' src='/images/intranet.png' />
              <TextBlockLeft>
              <HeavyTextDark>Intranet Web Portals</HeavyTextDark>
                <NormalText>
                  Collaboration in business has come a long way from the days of "sneaker net".  A well designed company intranet portal will serve as
                  a bastion of communication and team work for your business.  Let Phinite work with you to build the ideal intranet portal to fit your
                  organization.  We build custom solutions, tailor fit to your business needs, and seamlessly integrate with your current software.
                </NormalText>
              </TextBlockLeft>
            </ImageRowWrapperLeft>
          </ImageRowWrapper>
        </Blurb>
        <Blurb title='Mobile Development'>
          <ImageRowWrapper>
            <ImageRowWrapperLeft>
              <ImageRowImage alt='native mobile services' src='/images/native.png' />
              <TextBlockLeft>
                <HeavyTextDark>Native Apps</HeavyTextDark>
                <NormalText>
                  Our development team is experienced in development on all major smart phone brands.  A native built mobile app is the best way to
                  ensure your mobile customers experience the best their devices can offer.  Complex applications require speed and efficency that is
                  only possible when leveraging optimizations specific to each native platform.  If your app needs speed: Don't settle for the umbrella
                  solution.  Let us build you a lightening fast, efficient native mobile app that showcases the best your business has to offer.
                </NormalText>
              </TextBlockLeft>
            </ImageRowWrapperLeft>
            <ImageRowWrapperRight>
              <TextBlockRight>
              <HeavyTextDark>Custom Mobile Integrations</HeavyTextDark>
                <NormalText>
                  It's amazing all of the things people can do on their phones today.  Change a channel, fly a drone, order a pizza, turn off the lights...
                  You name it, people want to do it from their phones.  Phinite's mobile experts can make it happen.  Let us help you integrate mobile
                  phones with your current or future tech.
                </NormalText>
              </TextBlockRight>
              <ImageRowImage alt='mobile integration services' src='/images/mobilecontrol.png' />
            </ImageRowWrapperRight>
            <ImageRowWrapperLeft>
              <ImageRowImage alt='responsive mobile services' src='/images/responsive.png' />
              <TextBlockLeft>
              <HeavyTextDark>Responsive Mobile Apps</HeavyTextDark>
                <NormalText>
                  In our highly mobile society, it's highly likely that a large percentage of your customers are viewing your online presence from a
                  mobile device.  Having a poorly responsive web presence will discourage your customers, and drive them toward your competition.  We
                  are experts in responsive and mobile first design, and it's never too late to upgrade.  Let us help you keep your customers coming back
                  to you.  Phinite can build you a highly responsive app from the start, or upgrade your current applications at any point in development.
                </NormalText>
              </TextBlockLeft>
            </ImageRowWrapperLeft>
          </ImageRowWrapper>
        </Blurb>
        <Blurb title='Custom Software'>
          <ImageRowWrapper>
            <ImageRowWrapperLeft>
              <ImageRowImage alt='desktop software services' src='/images/desktop.png' />
              <TextBlockLeft>
                <HeavyTextDark>Desktop Applications</HeavyTextDark>
                <NormalText>
                  Many business applications demand performance.  Nothing in software says speed like a highly optimized custom desktop application.
                  We leverage decades of experience developing massive custom software applications, ranging from financial analytics to precise simulations
                  and video game engines.  When you need speed, Phinite can deliver.
                </NormalText>
              </TextBlockLeft>
            </ImageRowWrapperLeft>
            <ImageRowWrapperRight>
              <TextBlockRight>
              <HeavyTextDark>Hardware Integration</HeavyTextDark>
                <NormalText>
                  We live in a world that is more "connected" every day.  Computers and micro controllers are paramount to our connection with technology
                  and the people around us.  Robotics controllers, hardware encryption, IOT, and more.  We can help you combine quality software with your
                  hardware technologies, and deliver the ideal product to your customers.
                </NormalText>
              </TextBlockRight>
              <ImageRowImage alt='hardware integration services' src='/images/hardware.png' />
            </ImageRowWrapperRight>
          </ImageRowWrapper>
        </Blurb>
      </Content>
    </Wrapper>
  );
}

export default Services;