import React from 'react';
import styled from 'styled-components';
import { Media } from '../Styles';

const StyledDashboard = styled.div`
  background-color: ${ (props) => props.theme.highlight };
  color: ${ (props) => props.theme.primaryDark };
  font-family: Nunito Sans;
  padding-top: 50px;
`;

const ImageDiv = styled.div`
  background-image: linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.1)), url('/images/404.png');
  width: 100%;
  object-fit: cover;
  background-size: cover;
  ${Media.tablet`height: 400px;`}
`;

const TitleDiv = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  ${Media.tablet`width: 95%;`}
  height: 600px;
  ${Media.tablet`height: 400px;`}
  text-align: center;
`;

const LargeText = styled.span`
  font-family: Nunito Sans;
  font-weight: 900;
  font-size: ${ (props) => props.small ? '20px' : '48px'};
  ${Media.tablet`font-size: ${ (props) => props.small ? '12px' : '28px'};` }
  color: ${ (props) => props.theme.secondaryDark };
  text-shadow: 2px 2px #000000;
`;

const NotFound = (props) => (
  <StyledDashboard>
    <ImageDiv>
      <TitleDiv>
        <LargeText primary>OOPS! WE SEEM TO HAVE LOST THAT PIECE...</LargeText>
        <LargeText primary small>404 ERROR PAGE NOT FOUND</LargeText>
      </TitleDiv>
    </ImageDiv>
  </StyledDashboard>
);

export default NotFound;