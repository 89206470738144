import React from 'react';
import styled from 'styled-components';
import { HeavyText, Content, Wrapper, LogoMini, NormalText } from '../Styles';
import Blurb from '../common/Blurb';
import BioList from './BioList';
import Bio from './Bio';

const ImageLine = styled.div`
  display:flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 100%;
  align-items: center;
  justify-content: center;
`;

const ImageCol = styled.div`
  display:flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const About = () => { 
  window.scrollTo(0, 0); 
  return(
    <Wrapper>
      <Content>
        <Blurb title='Who We Are'>
          <Bio 
            isPerson={ false } 
            image='/images/sdvosb.png' 
            name='sdvosb logo' 
            text='Phinite Software LLC is proud to be a certified Service Disabled Veteran Owned Small Business (SDVOSB).  Our headquarters is located in Towson, 
              MD, with team members available throughout the United States.  We are passionate software development experts, who pride ourselves on creating 
              lasting quality from the beginning.' 
          />
        </Blurb>
        <Blurb title='Our Team'>
          <BioList />
          <ImageCol>
            <HeavyText>We're waiting on some photos, more to come...</HeavyText>
          </ImageCol>
        </Blurb>
        <Blurb title='What We Do'>
          <ImageCol>
            <NormalText>
              We leverage cutting edge technologies and decades of practical experience to create software-based solutions.  We'll step in and work with you regardless
              of where you are in your current project.  From inception to completion, and beyond; We offer complete SDLC services and solutions.
            </NormalText>
            <NormalText>
              Our team of experts have years of experience working with many of the most modern and relevant technologies and programming languages.
            </NormalText>
          </ImageCol>
          <ImageCol>
            <HeavyText>Such as...</HeavyText>
            <ImageLine>
              <LogoMini alt='nodejs logo' src='/images/nodejs.png'></LogoMini>
              <LogoMini alt='reactjs logo' src='/images/reactjs.png'></LogoMini>
              <LogoMini alt='golang logo' src='/images/golang.png'></LogoMini>
              <LogoMini alt='python logo' src='/images/python.png'></LogoMini>
              <LogoMini alt='rust logo' src='/images/rust.png'></LogoMini>
              <LogoMini alt='dotnet logo' src='/images/dotnet.png'></LogoMini>
              <LogoMini alt='dotnet core logo' src='/images/dotnetcore.png'></LogoMini>
              <LogoMini alt='java logo' src='/images/java.png'></LogoMini>
              <LogoMini alt='mongodb logo' src='/images/mongodb.jpg'></LogoMini>
              <LogoMini alt='mysql logo' src='/images/mysql.png'></LogoMini>
              <LogoMini alt='mssql logo' src='/images/mssql.png'></LogoMini>
              <LogoMini alt='redis logo' src='/images/redis.png'></LogoMini>
              <LogoMini alt='aws logo' src='/images/aws.png'></LogoMini>
              <LogoMini alt='nginx logo' src='/images/nginx.png'></LogoMini>
              <LogoMini alt='docker logo' src='/images/docker.png'></LogoMini>
            </ImageLine>
            <HeavyText>...and MANY more</HeavyText>
          </ImageCol>
        </Blurb>
      </Content>
    </Wrapper>
  );
}

export default About;