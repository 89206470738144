import { TOGGLE_RESPONSIVE } from '../action-types';

const initialState = {
  responsive: 'false',
  links: [
    { id: 0, name: 'About', link: '/about/', active: 'false' },
    { id: 1, name: 'Blog', link: '/blog/', active: 'false' },
    { id: 2, name: 'Services', link: '/services/', active: 'false' },
    { id: 3, name: 'Home', link: '/', active: 'true' },
  ]
}

export const navigation = (state = initialState, action) => {
  switch(action.type) {
    case TOGGLE_RESPONSIVE:
      return Object.assign({}, state, {
        responsive: state.responsive === 'false' ? 'true' : 'false'
      });
    default:
    return state;
  }
}

export default navigation;