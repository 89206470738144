import React from 'react';
import styled from 'styled-components';
import { Media, Title, TitleBar, BracketsStyles } from '../../Styles';

const Wrapper = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid ${ (props) => props.theme.primaryDark };
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 5px 10px #888888;
  background-color: #FFFFFF;
`;

const Brackets = styled.div`
  ${ BracketsStyles };
`;

const Children = styled.div`
  padding: 10px;
  margin-left: 40px;
  margin-right: 40px;
  ${Media.tablet`
    margin-left: auto;
    margin-right: auto;
  `}
  justify-content: center;
  align-content: center;
  text-align: center;
`;

const Blurb = (props) => (
  <Wrapper>
    <TitleBar>
      <Brackets noTitle={ props.noTitle }>&#123;</Brackets><Title>{ props.title }</Title><Brackets noTitle={ props.noTitle }>&#125;</Brackets>
    </TitleBar>
    <Children>{ props.children }</Children>
  </Wrapper>
);

export default Blurb;