import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${ (props) => props.theme.primaryDark };
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  color: ${ (props) => props.theme.highlight };
  font-family: Nunito Sans;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
`;

const SocialRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  text-align: center;
`;

const SocialImage = styled.img`
  height: 32px;
  display: block;
  max-height: 100%;
  width: auto;
  padding: 10px;
`

const Footer = () => (
  <Container>
    <hr width='100%' />
    <span>Contact us:</span>
    <span>E-mail: info@phinite-software.com</span>
    <span>Phone: (443) 384-7910</span>
    <SocialRow>
      <a href='https://www.facebook.com/phinitesoftware/' target="_blank" rel='noopener noreferrer'>
        <SocialImage alt='facebook logo' src='/images/facebook.png' />
      </a>
      <a href='https://twitter.com/PhiniteSoftware' target="_blank" rel='noopener noreferrer'>
        <SocialImage alt='twitter logo' src='/images/twitter.png' />
      </a>
      <a href='https://www.linkedin.com/company/phinite-software-llc/' target="_blank" rel='noopener noreferrer'>
        <SocialImage alt='linkedin logo' src='/images/linkedin.png' />
      </a>
    </SocialRow>
    <hr width='100%' />
    <span>&copy; Phinite Software LLC 2018</span>
  </Container>
);

export default Footer;