import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { Form, Text, TextArea } from 'informed';
import { Media, Title, TitleBar, BracketsStyles } from '../Styles';
import { sendContact } from '../../state/Contact/actions';

const BGWrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: ${ (props) => props.visible ? 'flex' : 'none' };
  overflow-behavior: none contain;
  overflow: hidden;
`;

const ModalMain = styled.div`
  position: fixed;
  background: ${ (props) => props.theme.highlight };
  border-radius: 8px;
  border: 2px solid ${ (props) => props.theme.black };
  width: 50%;
  height: auto;
  ${Media.tablet`
    border-radius: 0px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  `};
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  ${ (props) => props.loading ? 'display: none;' : ''};
`;

const SpinnerWrapper = styled.div`
  position: fixed;
  z-index: 20;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const Spin = keyframes`
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid ${ (props) => props.theme.primary };
  width: 120px;
  height: 120px;
  -webkit-animation: ${Spin} 2s linear infinite;
  animation: ${Spin} 2s linear infinite;
  ${ (props) => !props.loading ? 'display: none;' : ''};
`;

const SuccessBar = styled.div`
  position: fixed;
  background: ${ (props) => props.theme.info };
  border-radius: 8px;
  border: 2px solid ${ (props) => props.theme.black };
  width: 50%;
  height: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: Nunito Sans;
  font-weight: bold;
  justify-content: center;
  align-content: center;
  padding: 10px;
  text-align: center;
  color: ${ (props) => props.theme.highlight };
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
`;

const InputBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding-bottom: 20px;
  margin: auto;
  width: 40%;

  ${Media.tablet`width: 90%;`}
`;

const TextBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding-bottom: 20px;
  margin: auto;
  width: 90%;
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-around;
  align-itmes: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 50px;
  margin: auto;
  width: 90%;
`

const ErrorBlock = styled.div`
  display: ${ (props) => props.error === 'true' ? 'flex' : 'none' };
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  margin: 20px auto;
  width: 90%;
  color: ${ (props) => props.theme.highlight }
  background: ${ (props) => props.theme.error }
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
`;

const StyledInput = styled(Text)`
  display: flex;
  width: 100%;
  border-radius: 4px;
  border: 2px solid ${ (props) => props.error !== '' ? props.theme.error : props.theme.primary };
  padding: 10px 8px;
  font-weight: bold;
  font-size: 16px;
  background-color: ${ (props) => props.theme.highlight };
`;

const StyledLabel = styled.label`
  color: ${ (props) => props.theme.primaryDark };
  font-size: 16px;
  font-family: Nunito Sans;
  font-weight: 400;
`;

const StyledTextArea = styled(TextArea)`
  display: flex;
  z-index: 11;
  border-radius: 4px;
  border: 2px solid ${ (props) => props.theme.primary };
  padding: 10px 8px;
  width: 100%
  font-weight: bold;
  font-size: 16px;
  background-color: ${ (props) => props.theme.highlight };
  height: 10em;
`;

const Button = styled.button`
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  margin: .5em auto;
  width: 20em;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  font-family: Nunito Sans;
  font-weight: bold;
  background-color: ${ (props) => props.primary ? props.theme.primary : props.theme.secondaryDark };
  color: ${ (props) => props.theme.highlight };
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;

  &:hover {
    background-color: ${ (props) => props.primary ? props.theme.secondaryDark : props.theme.primary };
    border-radius: 6px;
  }
`;

const Brackets = styled.div`
  ${ BracketsStyles };
`;

const SuccessDiv = (props) => {
  if(props.status !== '') {
    return <SuccessBar>{ props.status }</SuccessBar>;
  } else {
    return <div></div>;
  }
}

export class Contact extends Component {
  constructor(props) {
    super(props);

    this.setFormApi = this.setFormApi.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.validateName = this.validateName.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validatePhone = this.validatePhone.bind(this);

    this.state = {
      nameError: '',
      emailError: '',
      phoneError: ''
    }
  }

  setFormApi(formApi) {
    this.formApi = formApi;
  }

  handleToggle(e) {
    e.preventDefault();
    this.formApi.reset();
    this.setState({
      nameError: '',
      emailError: '',
      phoneError: ''
    });
    this.props.toggle();
  }

  handleSubmit(e) {
    e.preventDefault();
    
    if(this.formApi.getError('name'))
      this.setState({ nameError: this.formApi.getState().errors.name });
    else
      this.setState({ nameError: '' });

    if(this.formApi.getError('phone'))
      this.setState({ phoneError: this.formApi.getState().errors.phone });
    else
      this.setState({ phoneError: '' });

    if(this.formApi.getError('email'))
      this.setState({ emailError: this.formApi.getState().errors.email });
    else
      this.setState({ emailError: '' });

    if(!this.formApi.getState().values.email && !this.formApi.getState().values.phone) {
      this.setState({ phoneError: 'Phone or Email required', emailError: 'Phone or Email required' });
      return;
    }

    this.formApi.submitForm();
  }

  validateName(value) {
    return !value ? 'Name is empty' : null;
  }

  validateEmail(value) {
    if(!value) return null;

    return !value.match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/) ? 'Invalid email' : null;
    // return !value.match(/^([a-zA-Z0-9_\\-\\.]+)@(([[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(]?)$/) ? 'Invalid email' : null;
  }

  validatePhone(value) {
    if(!value) return null;

    return !value.match(/^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4}|[a-zA-Z0-9]{7})$/) ? 'Invalid phone' : null;
  }

  render() {
    return (
      <BGWrapper visible={ this.props.visible }>
        <Form id='contact-form' onSubmit={() => { this.props.sendContact(this.formApi.getState().values); this.formApi.reset(); } } getApi={this.setFormApi}>
          <div>
            <SpinnerWrapper>
                <Spinner loading={ this.props.isSending }/>
            </SpinnerWrapper>
            <SuccessDiv status={this.props.sendStatus} />
            <ModalMain loading={ this.props.isSending || this.props.sendStatus !== '' }>
              <TitleBar>
                <Brackets>&#123;</Brackets><Title>Contact Us</Title><Brackets>&#125;</Brackets>
              </TitleBar>  
              <InputRow>
                <InputBlock>
                  <StyledLabel htmlFor='state-name'>
                    Name
                  </StyledLabel>
                  <StyledInput error={ this.state.nameError } field='name' id='state-name' placeholder='Your full name' validateOnBlur validate={ this.validateName } />
                </InputBlock>
                <InputBlock>
                  <StyledLabel htmlFor='state-company'>
                    Company
                  </StyledLabel>
                  <StyledInput error='' field='company' id='state-company' placeholder='Your company name' />
                </InputBlock>
                <InputBlock>
                  <StyledLabel htmlFor='state-email'>
                    Email
                  </StyledLabel>
                  <StyledInput error={ this.state.emailError } field='email' id='state-email' placeholder='Your work email' validateOnBlur validate={ this.validateEmail }/>
                </InputBlock>
                <InputBlock>
                  <StyledLabel htmlFor='state-phone'>
                    Phone
                  </StyledLabel>
                  <StyledInput error={ this.state.phoneError } field='phone' id='state-phone' placeholder='Your work phone' validateOnBlur validate={ this.validatePhone }/>
                </InputBlock>
                <TextBlock>
                  <StyledLabel htmlFor='state-comment'>
                    How may we help?
                  </StyledLabel>
                  <StyledTextArea maxLength="2000" field='comment' id='state-comment' placeholder='Please briefly describe how we can help...'/>
                </TextBlock>
                <ErrorBlock error={ this.state.nameError !== '' || this.state.phoneError !== '' || this.state.emailError !== '' ? 'true' : '' }>
                  { this.state.nameError || this.state.phoneError || this.state.emailError }
                </ErrorBlock>
                <ButtonBlock>
                  <Button onClick={ this.handleToggle } >Close</Button>
                  <Button onClick={ this.handleSubmit } primary>Submit</Button>
                </ButtonBlock>
              </InputRow>  
            </ModalMain>
          </div>
        </Form>
      </BGWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    isSending: state.contact.isSending,
    sendStatus: state.contact.sendStatus
  }
}

const mapDispatchToProps = dispatch => {
  return ({
    sendContact: (data) => dispatch(sendContact(data))
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);